export const environment = {
  production: false,
  okta: {
    authConfig: {
      issuer: 'https://onentt.okta.com/oauth2/ausf3mzucjRGKYWLy417',
      clientId: '0oafbxeqmbkQ7ydpU417',
      redirectUri:
        'https://ada-suite-frontend-dev.deptapps-instances.automation-coe.com/login/callback',
      scopes: ['openid', 'offline_access'],
    },
    interceptor: {
      urls: [
        'https://axet-core-dev.azurewebsites.net/api',
        'https://ada-suite-frontend-dev.deptapps-instances.automation-coe.com',
      ],
    },
  },
  backend: {
    'AXET-CORE': 'https://axet-core-dev.azurewebsites.net/api',
    'ADA-API':
      'https://ada-suite-frontend-dev.deptapps-instances.automation-coe.com/api-dev',
    'AUTOMATION-TOOLS':
      'https://ada-suite-frontend-dev.deptapps-instances.automation-coe.com/api-dev',
  },
};
